.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background: #2B518ECC;
}

.modalPosition {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
}

.modal {
    width: 90%;
    z-index: 30;
    overflow: hidden;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 34rem;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 3rem 1.6rem 0.8rem 1.6rem;
    color: #2c3143;
    font-size: 24px;
    font-family: freight-sans-pro b, sans-serif;
}

.contentRow {
    display: flex;
    justify-content: space-between;
    color: #2c3143;
    font-family: freight-sans-pro, sans-serif;
    padding-bottom: 16px;
}

.content {
    font-family: freight-sans-pro b, sans-serif;
    font-size: 18px;
    color: #2c3143;
    padding: 1.8rem 2.5rem 1.5rem 2.5rem;
}

/*Media for small screen*/
@media (max-width: 768px) {
    .content {
        padding: 1.8rem 1.5rem 1.5rem 1.5rem;
    }
}

.actions {
    padding: 1rem 1.5rem 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
}

.error {
    padding-top: 5px;
    padding-left: 10px;
    color: #FE5C55;
}

.cross {
    cursor: pointer;
    font-size: 40px;
    color: #2c3143;
    transition: all 0.2s;
    transform-origin: 50% 50%;
}

.cross:hover {
    transform: scale(1.15);
}

.warning {
    padding-top: 5px;
    padding-left: 20px;
    color: #FAAA5A;
}

.dark_button {
    background-color: #2C3143;
    height: 32px;
    width: 120px;
    padding: 0 16px;
    border-radius: 8px;
}
