.cloze_select_top_header {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0 16px 16px;
    border-radius: 8px;
}

.cloze_select_buttons {
    width: 100px;
    padding-right: 0;
    text-align: right;
}

.cloze_select_button_active {
    font-family: freight-sans-pro b, sans-serif;
    color: #2C3143;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    background-color: #FFFFFF;
    border: 1px solid #43D281;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 5px 12px;
    width: 87px;
    height: 32px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_check {
    margin-right: 8px;
    margin-left: 12px;
    pointer-events: none;
}

.cloze_select_dest_flag {
    margin-right: 8px;
    margin-left: 12px;
    pointer-events: none;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}

.cloze_select_from_flag {
    margin-right: 8px;
    margin-left: 12px;
    pointer-events: none;
    height: 32px;
    border-radius: 4px;
}

.cloze_select_title {
    vertical-align: top;
    text-justify: none;
}

.cloze_select_fixed_one {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
}

.cloze_select_fixed_one_task {
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    padding-right: 0;
}

.cloze_select_task_img {
    padding-right: 12px;
    position: relative;
    top: -8px;
    margin-bottom: 22px;
}

.cloze_select_task_name {
    padding-right: 12px;
    position: relative;
    top: -16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_diff_img {
    padding-right: 12px;
    position: relative;
    top: -8px;
    margin-bottom: 22px;
}

.cloze_select_diff_img_task {
    padding-right: 12px;
    position: relative;
    top: -8px;
    margin-bottom: 22px;
    height: 40px;
    width: 52px;
}

.cloze_select_diff_name {
    position: relative;
    top: -28px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_diff_name_task {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_diff_expl {
    padding-right: 12px;
    position: relative;
    top: -52px;
    left: 52px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_opasity {
    opacity: 0.4;
    cursor: default !important;
}

.cloze_select_exercise {
    height: 137px;
}

.cloze_select_row_2 {
    height: 526px;
}

@media (max-width: 800px) {
    .cloze_select_hide_2 {
        display: none;
    }
}

@media (max-width: 1050px) {
    .cloze_select_hide {
        display: none;
    }
}

@media (max-width: 800px) {


    .cloze_select_exercise {
        height: 100px;
    }

    .cloze_select_3 {
        padding-right: 40px;
    }

    .cloze_select_row {
        height: auto;
    }

    .cloze_select_row_2 {
        height: auto;
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .cloze_select_3 {
        padding-right: 50px;
    }

    .cloze_select_4 {
        padding-right: 0;
    }
}

.cloze_select_diff_main {
    padding-right: 350px;
}

.cloze_select_link {
    color: #2C3143;
    font-size: 1.1em;
    cursor: pointer;
    padding-right: 16px;
    padding-bottom: 4px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_select_link:hover {
    color: #666b83;
}

.cloze_select_outline_dark {
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
}

.cloze_select_back_h3 {
    font-family: freight-sans-pro b, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
    padding-left: 6px;
}

.cloze_select_back_h3:hover {
    color: #585b75;
    cursor: pointer;
}

.cloze_select_row {
    height: 478px;
}

.cloze_hide_input {
    display: none;
}
