.hr_l {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative; left:4px;
}

.hr_r {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative; top:-1px; right:20px;
}