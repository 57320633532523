.arrows {
    color: #68AEB8;
    padding-left: 12px;
}

.refresh-icon {
    color: #68AEB8;
    font-size: 1.8em !important;
    cursor: pointer;
}

.refresh-right {
    display: flex;
    justify-content: flex-end;
}

.loading {
    padding-bottom: 9px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}