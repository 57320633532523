.cloze_booklist_list {
    list-style: none;
    margin: 0;
    display: inline-flex;
    flex-flow: row wrap;
    padding: 10px 30px 10px 30px;
    align-items: flex-start;
}

@media (max-width: 600px) {
    .cloze_booklist_list {
        padding: 10px 5px 10px 5px;
    }
}

.cloze_booklist_list_hide {
    display: none;
}

.cloze_booklist_upload_box {
    width: 100% !important;
    background: #EEF0F4 !important;
    border: 2px dashed #B2B8CB !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;

    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 16px !important;

    color: #2C3143;
}

.cloze_booklist_text_file {
    font-family: 'freight-sans-pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.cloze_booklist_warning {
    color: #FE5C55;
    font-size: 18px;
}

.cloze_fadeOut {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.cloze_fadeIn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}