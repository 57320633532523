.header-card {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #77002e;
    font-family: "Vollkorn", "Times New Roman", serif;
    font-size: 1.5rem;
    font-feature-settings: "lnum" on;
    color: #7db07a;
    height: 60px;
}

/*.main-card {*/
/*    height: 166px;*/
/*}*/

.footer-card {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #f7f8fa;
    height: 102px;
}

.guess-footer {
    width: 648px;
    border-radius: 10px;
}

@media (min-width: 336px) {
    .guess-footer {
        max-width: 336px;
    }
}

@media (min-width: 672px) {
    .container {
        max-width: 672px;
    }
}

h3 {
    font-size: 1.3rem;
    color: #2c292b;
}

.learn-content {
    font-family: "Vollkorn", "Times New Roman", serif;
    font-size: 33px;
    font-feature-settings: "lnum" on;
    color: #037889;
    display: inline;
    align-items: center;
    line-height: 2.5rem;
}

#hide {
    position: absolute;
    opacity: 0;
    z-index: -100;
    white-space: pre;
}

.card-grammar {
    background-color: #f5f5f5;
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: inline-flex;
    flex-direction: row;
    height: 37px;
}

.btn-sm {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.user {
    color: #939393;
}

.answer-input {
    border: 0;
    box-sizing: content-box;
    padding: 5px 8px 5px 8px;
    outline: none;
    max-width: 400px;
    min-width: 6ch;
    margin: 0 8px 0 8px;
}

.initial-input {
    background-color: #f5f5f5;
    color: #037889;
}

.correct-answer-input {
    color: #70d29f;
    caret-color: transparent;
    pointer-events: none;
    border: 0;
    background: transparent;
}

.wrong-answer-input {
    color: #df6c65;
    caret-color: transparent;
    pointer-events: none;
    outline: none;
    background: transparent;
}
