/*! react-sidenav v0.5.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-sidenav */

/*CSS color filter generator*/
/* https://codepen.io/sosuke/pen/Pjoqqp */

.sidenav---sidenav---_2tBP {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    min-width: 64px;
    background: #ffffff;
    box-shadow: 0 0.3125rem 0.625rem 0 rgb(0 0 0 / 8%);
    -webkit-transition: min-width 0.2s;
    -moz-transition: min-width 0.2s;
    -o-transition: min-width 0.2s;
    -ms-transition: min-width 0.2s;
    transition: min-width 0.2s;
}

.logo {
    padding-left: 10px;
    border-bottom: 1px solid #dcdcde;
    height: 50px;
}

.sidenav---sidenav---_2tBP:hover {
    min-width: 240px;
}

.mobile-menu {
    position: absolute;
    top: 15px;
    /*bottom: 0;*/
    left: 25px;
    z-index: 10;
    display: none;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .mobile-menu {
        display: block;
    }

    .sidenav---sidenav---_2tBP {
        display: none;
        width: 240px;
    }

    .sidenav---navtext---1AE_f {
        visibility: visible;
        opacity: 1;
    }
}

@media only screen and (min-width: 801px) {
    .sidenav---navtext---1AE_f {
        width: 0;
        visibility: hidden;
        opacity: 0;
    }
}

.sidenav---sidenav---_2tBP:hover .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}


.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
    display: none;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
    display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 240px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
    visibility: visible;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
    cursor: pointer;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    position: static;
    border: none;
    float: left;
    clear: both;
    width: 100%;
    background: transparent;
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    display: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 {
    position: relative;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
    content: " ";
    width: 100%;
    height: 28px;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
    display: none;
}

/* Link hover */
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover::before {
    background: #fff;
    opacity: 0.15;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
    filter: alpha(opacity=15);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
    content: " ";
    width: 100%;
    height: 28px;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
    color: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before {
    content: " ";
    width: 2px;
    height: 20px;
    left: 10px;
    top: 4px;
    position: absolute;
    border-left: 2px #fff solid;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
    background: transparent;
    color: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
    color: #c74fbd;
    padding: 0 14px 0 20px;
    line-height: 28px;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 + .sidenav---icon-bar---u1f02 {
    margin-top: 4px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    width: 25px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(2) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(3) {
    margin-top: -8px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sidenav---sidenav-nav---3tvij {
    float: left;
    padding: 0;
    margin: 0;
    clear: both;
    list-style: none;
    width: 100%;
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- {
    clear: both;
    position: relative;
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
    cursor: default;
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
    background: #fff;
    opacity: 0.15;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
    filter: alpha(opacity=15);
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
    background: #eff0f4;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
    background: #000;
    opacity: 0.25;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    filter: alpha(opacity=25);
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
    background: #000;
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

/* Active page link*/
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}

/* Active kink */
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    /*color: #ac6ed1;*/
    /*font-size: 16px;*/
    filter: invert(64%) sepia(73%) saturate(2640%) hue-rotate(225deg) brightness(84%) contrast(93%);
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    background: transparent;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
    position: relative;
    display: block;
    line-height: 50px;
    height: 50px;
    white-space: nowrap;
    text-decoration: none;
    color: #fff;
    /*font-size: 16px;*/
    cursor: pointer;
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
    outline: 0;
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    z-index: -1;
}

/* Link */
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    /*color: #2C3143;*/
    /*font-size: 16px;*/
}

.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    /*color: #2C3143;*/
    /*font-size: 16px;*/
}

/* Inactive link*/
.sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    display: block;
    float: left;
    width: 64px;
    height: 50px;
    margin-right: -6px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    line-height: 50px;
    text-align: center;
}


.sidenav---sidenav-subnav---1EN61 {
    min-width: 200px;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    left: 64px;
    background: #fff;
    border: 1px #bbb solid;
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px #ccc solid;
    /*font-size: 16px;*/
    font-weight: normal;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
    margin-top: 8px;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child {
    margin-bottom: 8px;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
    font-weight: bold;
    background: none;
    cursor: default;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T:hover {
    background: #fff;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
    display: block;
    text-decoration: none;
    /*color: #222;*/
    /*font-size: 16px;*/
    line-height: 30px;
    padding: 0 24px;
    cursor: pointer;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
    background: #b94e4e;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:focus {
    outline: 0;
}

.sidenav---sidenav---_2tBP {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 20px;
}

.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

.active-link {
    padding-bottom: 3px;
    font-family: freight-sans-pro, sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #ac6ed1;
    filter: invert(53%) sepia(70%) saturate(1372%) hue-rotate(229deg) brightness(88%) contrast(83%);
}

.inactive-link {
    padding-bottom: 3px;
    font-family: freight-sans-pro, sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #2C3143;
    filter: invert(20%) sepia(1%) saturate(0%) hue-rotate(275deg) brightness(87%) contrast(76%);
}

