.cloze_menu_top_header {
    background-color: #ffffff;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    padding: 16px;
    border-radius: 8px;
}

.cloze_menu_top_middle {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
}

.cloze_menu_select_header {
    background-color: #ffffff;
    padding-top: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cloze_menu_select_buttons {
    background-color: #ffffff;
    padding: 0 16px 16px 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.cloze_menu_select_language {
    padding: 0 16px 0 16px;
}

@media (min-width: 600px) {
    .cloze_menu_container {
        padding-left: 92px;
        padding-right: 28px;
        text-align: justify;
        text-justify: inter-word;
    }
}

@media (max-width: 600px) {
    .cloze_hide {
        display: none;
    }
}

.cloze_cefr_button {
    font-family: freight-sans-pro b, sans-serif;
    color: #2C3143;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    background-color: #EEF0F4;
    border-radius: 8px;
    margin: 10px 8px;
    cursor: pointer;

    width: 63px;
    height: 32px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_cefr_button:hover {
    background-color: #e8eaef;
}

.cloze_cefr_button_active {
    font-family: freight-sans-pro b, sans-serif;
    color: #2C3143;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    background-color: #FFFFFF;
    border: 1px solid #43D281;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 8px;
    cursor: pointer;

    width: 87px;
    height: 32px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_menu_count {
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 8px;
    pointer-events: none;
}

.cloze_menu_check {
    margin-right: 8px;
    margin-left: 3px;
    pointer-events: none;
    display: none;
}

.cloze_menu_med {
    padding-left: 30px;
    padding-bottom: 10px;
}

@media (max-width: 600px) {
    .cloze_menu_med {
        padding-left: 0;
    }
}

.cloze_menu_fixed_one {
    -ms-flex: 0 0 640px;
    flex: 0 0 640px;
}


.cloze_cefr_flag {
    width: 100px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_cefr_flag_not_selected {
    width: 100px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.2;
}

.cloze_cefr_flag_selected {
    width: 100px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_cefr_flag_not_selected:hover {
    opacity: 0.6;
}

.cloze_cefr_flag:hover {
    opacity: 0.8;
}

.cloze_hide_input {
    display: none;
}