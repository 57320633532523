@media (min-width: 800px) {
    .frame {
        padding-left: 92px;
        padding-right: 28px;
        text-align: justify;
        text-justify: inter-word;
    }
}

/*Mobile view*/
@media (max-width: 800px) {
    .frame {
        text-align: justify;
        text-justify: inter-word;
        margin: 0 10px;
    }
}