.data-pipeline-input {
    width: 280px;
}

.data-pipeline-jobs {
    width: 500px;
}

.data-pipeline-msg {
    color: #0bcb9a;
    font-size: 20px;
    font-weight: 500;
}

.data-pipeline-select-search {
    width: 280px;
}