/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * freight-sans-compressed-pro:
 *   - http://typekit.com/eulas/00000000000000000001775b
 *   - http://typekit.com/eulas/00000000000000000001775c
 *   - http://typekit.com/eulas/00000000000000000001775d
 *   - http://typekit.com/eulas/00000000000000000001775e
 *   - http://typekit.com/eulas/000000000000000077359e6a
 *   - http://typekit.com/eulas/000000000000000077359e70
 *   - http://typekit.com/eulas/000000000000000077359e5c
 *   - http://typekit.com/eulas/000000000000000077359e5f
 * freight-sans-condensed-pro:
 *   - http://typekit.com/eulas/000000000000000077359e60
 *   - http://typekit.com/eulas/000000000000000077359e63
 *   - http://typekit.com/eulas/000000000000000077359e67
 *   - http://typekit.com/eulas/000000000000000077359e6e
 * freight-sans-condensed-pro-l:
 *   - http://typekit.com/eulas/000000000000000077359e66
 *   - http://typekit.com/eulas/000000000000000077359e6c
 * freight-sans-condensed-pro-u:
 *   - http://typekit.com/eulas/000000000000000077359e6d
 *   - http://typekit.com/eulas/000000000000000077359e73
 * freight-sans-pro:
 *   - http://typekit.com/eulas/000000000000000000010b5a
 *   - http://typekit.com/eulas/000000000000000000010b5b
 *   - http://typekit.com/eulas/000000000000000000010b60
 *   - http://typekit.com/eulas/000000000000000000010b61
 * freight-sans-pro-lights:
 *   - http://typekit.com/eulas/000000000000000077359e71
 *   - http://typekit.com/eulas/000000000000000077359e74
 * freight-sans-pro-ultra:
 *   - http://typekit.com/eulas/000000000000000077359e6b
 *   - http://typekit.com/eulas/000000000000000077359e72
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-02-28 09:12:35 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=hev7dim&ht=tk&f=10954.13453.13454.13455.25657.25658.25659.25660.25670.25671.25672.25673.41781.41782.41787.41788.41793.41794.41795.41796.41797.41798.41799.41800&a=40505082&app=typekit&e=css");

@font-face {
font-family:"freight-sans-pro";
src:url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro";
src:url("https://use.typekit.net/af/3df5fe/000000000000000000010b5b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3df5fe/000000000000000000010b5b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3df5fe/000000000000000000010b5b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro";
src:url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro";
src:url("https://use.typekit.net/af/65fc7d/000000000000000000010b61/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/65fc7d/000000000000000000010b61/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/65fc7d/000000000000000000010b61/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/1c306c/00000000000000000001775b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1c306c/00000000000000000001775b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1c306c/00000000000000000001775b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/370d64/00000000000000000001775c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/370d64/00000000000000000001775c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/370d64/00000000000000000001775c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/5728cf/00000000000000000001775d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5728cf/00000000000000000001775d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5728cf/00000000000000000001775d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/158976/00000000000000000001775e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/158976/00000000000000000001775e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/158976/00000000000000000001775e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro";
src:url("https://use.typekit.net/af/577bf0/000000000000000077359e60/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/577bf0/000000000000000077359e60/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/577bf0/000000000000000077359e60/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro";
src:url("https://use.typekit.net/af/973704/000000000000000077359e63/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/973704/000000000000000077359e63/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/973704/000000000000000077359e63/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro";
src:url("https://use.typekit.net/af/059ca0/000000000000000077359e67/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/059ca0/000000000000000077359e67/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/059ca0/000000000000000077359e67/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro";
src:url("https://use.typekit.net/af/10ced3/000000000000000077359e6e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/10ced3/000000000000000077359e6e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/10ced3/000000000000000077359e6e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/46130c/000000000000000077359e6a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/46130c/000000000000000077359e6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/46130c/000000000000000077359e6a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/0098c5/000000000000000077359e70/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/0098c5/000000000000000077359e70/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/0098c5/000000000000000077359e70/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro-l";
src:url("https://use.typekit.net/af/fd0e98/000000000000000077359e66/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/fd0e98/000000000000000077359e66/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/fd0e98/000000000000000077359e66/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro-l";
src:url("https://use.typekit.net/af/3a5d09/000000000000000077359e6c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/3a5d09/000000000000000077359e6c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/3a5d09/000000000000000077359e6c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro-lights";
src:url("https://use.typekit.net/af/717a7b/000000000000000077359e71/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/717a7b/000000000000000077359e71/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/717a7b/000000000000000077359e71/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro-lights";
src:url("https://use.typekit.net/af/5769ec/000000000000000077359e74/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/5769ec/000000000000000077359e74/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/5769ec/000000000000000077359e74/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/8c7e21/000000000000000077359e5c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/8c7e21/000000000000000077359e5c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/8c7e21/000000000000000077359e5c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-compressed-pro";
src:url("https://use.typekit.net/af/be7bcf/000000000000000077359e5f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/be7bcf/000000000000000077359e5f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/be7bcf/000000000000000077359e5f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro-u";
src:url("https://use.typekit.net/af/621ee7/000000000000000077359e6d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/621ee7/000000000000000077359e6d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/621ee7/000000000000000077359e6d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-condensed-pro-u";
src:url("https://use.typekit.net/af/81d26e/000000000000000077359e73/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/81d26e/000000000000000077359e73/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/81d26e/000000000000000077359e73/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro-ultra";
src:url("https://use.typekit.net/af/0d57ef/000000000000000077359e6b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/0d57ef/000000000000000077359e6b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/0d57ef/000000000000000077359e6b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"freight-sans-pro-ultra";
src:url("https://use.typekit.net/af/308da0/000000000000000077359e72/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/308da0/000000000000000077359e72/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/308da0/000000000000000077359e72/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

.tk-freight-sans-pro { font-family: "freight-sans-pro",sans-serif; }
.tk-freight-sans-compressed-pro { font-family: "freight-sans-compressed-pro",sans-serif; }
.tk-freight-sans-condensed-pro { font-family: "freight-sans-condensed-pro",sans-serif; }
.tk-freight-sans-compressed-pro { font-family: "freight-sans-compressed-pro",sans-serif; }
.tk-freight-sans-condensed-pro-l { font-family: "freight-sans-condensed-pro-l",sans-serif; }
.tk-freight-sans-pro-lights { font-family: "freight-sans-pro-lights",sans-serif; }
.tk-freight-sans-compressed-pro { font-family: "freight-sans-compressed-pro",sans-serif; }
.tk-freight-sans-condensed-pro-u { font-family: "freight-sans-condensed-pro-u",sans-serif; }
.tk-freight-sans-pro-ultra { font-family: "freight-sans-pro-ultra",sans-serif; }