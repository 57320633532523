.hr_l {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative; right: 16px;
}

.hr_r {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative; top:-1px; left:32px;
}