.tasks-flex-end {
    display: flex;
    justify-content: flex-end;
}

.tasks-correct {
    color: #00b300;
    font-weight: bolder;
}

.tasks-first-gap {
    color: #626C91;
    background: #EEF0F4;
    border-radius: 8px;
    display: inline-block;
    height: 28px;
    padding: 0 8px 0 8px;
    border: 2px solid #6CD5E5;
}

.tasks-gap {
    color: #626C91;
    background: #EEF0F4;
    border-radius: 8px;
    display: inline-block;
    height: 27px;
    padding: 0 8px 0 8px;
}

.tasks-vocabulary-selected {
    font-size: 16.5px;
    white-space: pre-wrap;
    line-height: 1.8em;
    font-weight: 500;
    color: #B2B8CB;
}

.tasks-gap-correct {
    color: #2C3143;
    background: #D9F6E6;
    border-radius: 8px;
    display: inline-block;
    height: 27px;
    padding: 0 8px 0 8px;
}

.tasks_hint_normal {
    text-align: right;
    display: inline-block;
    border: 2px solid #b2b8cb;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px 4px 16px 4px;
    padding: 4px 16px;
    color: #2C3143;
}

.tasks_selected_words {
    font-weight: 500;
    text-align: right;
    display: inline-block;
    border: 1px solid #D5DBE5;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 8px 4px 8px 4px;
    padding: 8px 12px;
    color: #2C3143;
}

.task-text {
    font-size: 16.5px;
    white-space: pre-wrap;
    line-height: 1.8em;
    color: #2C3143;
}

.tasks_hint_assotiation {
    text-align: right;
    display: inline-block;
    border: 1px solid #c3c9d7;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px 4px 16px 4px;
    padding: 2px 8px;
    color: #2c3143;
}

.tasks_boarder {
    border-bottom: 1px solid #D5DBE5FF;
}

.tasks_bottom {
    background: #D5DBE5;
    border-radius: 0 0 8px 8px;
}

.tasks_bottom_button {
    background: #D5DBE5;
    border-radius: 8px 8px 8px 8px;
}

/*Input*/
.tasks-answer-input {
    width: 100%;
    height: 40px;
    font-size: 1.2em;
    color: #037889;
    background: #EEF0F4;
    border: 1px solid #FFFFFF;
    border-bottom: 2px solid #6CD5E5;
    padding-left: 10px;
    z-index: -1;
}

.tasks-answer-input:focus {
    outline: none;
}

.tasks-answer-input-correct {
    color: #43D281;
    font-size: 1.2em;
    width: 100%;
    height: 40px;
    border: 1px solid #FFFFFF;
    border-bottom: 2px solid #43D281;
    padding-left: 10px;
    background: #FFFFFF;
}

.tasks-answer-input-correct:focus {
    outline: none;
}

.tasks-answer-input-incorrect {
    color: #FE5C55;
    font-size: 1.2em;
    width: 100%;
    height: 40px;
    border: 1px solid #FFFFFF;
    border-bottom: 2px solid #FE5C55;
    padding-left: 10px;
    background: #ffffff;
}

.tasks-answer-input-incorrect:focus {
    outline: none;
}

.tasks-overlay-visible {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
    visibility: visible;
    padding-right: 24px;
    height: 20px;
}

.tasks-overlay-hidden {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
    visibility: hidden;
    height: 20px;
}

.tasks-hints {
    color: #2C3143;
    font-size: 16.5px;
    padding: 6px 16px;
    border-bottom: 2px solid #D5DBE5;
    display: inline-block;
    margin-right: 8px;
}

.tasks-hints-answered {
    color: #B2B8CB;
    font-size: 16.5px;
    padding: 6px 16px;
    border-bottom: 2px solid #EEF0F4;
    display: inline-block;
    margin-right: 8px;
}

.tasks_hr_l {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative;
    left: 0px;
}

.tasks_hr_r {
    border: 0;
    border-top: 1px solid rgb(213, 219, 229);
    position: relative;
    top: -1px;
    right: 0px;
}

.tasks-word {
    color: #2C3143;
}

.tasks-word:hover {
    cursor: pointer;
    background: #EEF0F4;
    padding: 2px 0 2px 0;
    border-radius: 4px;
}

.tasks-cross {
    padding-left: 8px;
}

.tasks-cross:hover {
    cursor: pointer;
    opacity: 0.7;
}

.tasks-footer-card {
    background-color: #f7f8fa;
}

.tasks-remove-all {
    cursor: pointer;
}

.tasks-remove-all:hover {
    opacity: 0.7;
    color: #FE5C55;
}

.task-narrow {
    padding: 0 1px 0 1px;
}

/*GG*/
.tasks-learn-content {
    font-family: "Vollkorn", "Times New Roman", serif;
    font-feature-settings: "lnum" on;
    color: #037889;
    display: inline;
    align-items: center;
    line-height: 2.5rem;
}

.gg-answer-input {
    border: 0;
    box-sizing: content-box;
    padding: 2px 8px 2px 8px;
    outline: none;
    max-width: 400px;
    min-width: 6ch;
    margin: 0 8px 0 8px;
}

/* ------------ Translate task ---------------- */
.translate-answer-input {
    border: 0;
    box-sizing: content-box;
    padding: 0 8px 0 8px;
    outline: none;
    max-width: 400px;
}

.translate-initial-input {
    box-sizing: content-box;
    padding: 2px 8px 2px 8px;
    outline: none;
    max-width: 400px;
    height: 1.4em;
    background-color: #f2f3f8;
    color: #626c91;
    caret-color: #626c91;
    border-bottom: 2px solid #6CD5E5;
    position: relative;
    top: -4px;
}

.translate-empty-cap {
    border-bottom: 2px solid #D5DBE5FF;
    position: relative;
    top: -2px;
}

.translate-correct-cap {
    color: #43D281;
}

.translate-incorrect-cap {
    color: #FE5C55;
    border-bottom: 2px solid #FE5C55;
    position: relative;
    top: -2px;
}

.translate-neutral-cap {
    display: inline-block;
    color: #FAAA5A;
    border-bottom: 2px solid #D5DBE5FF;
    position: relative;
    top: 0;
    height: 26px;
}

.translate-correct-answer-input {
    box-sizing: content-box;
    padding: 0 8px 0 8px;
    outline: none;
    max-width: 400px;
    min-width: 6ch;
    height: 1.4em;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 2px #D9F6E6;
    color: #70d29f;
    caret-color: transparent;
    pointer-events: none;
}

.translate-wrong-answer-input {
    box-sizing: content-box;
    padding: 0 8px 0 8px;
    outline: none;
    max-width: 400px;
    min-width: 6ch;
    height: 1.4em;
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 2px #f3afad;
    color: #df6c65;
    caret-color: transparent;
    pointer-events: none;
}

.translate-tasks-gap {
    box-sizing: content-box;
    padding: 0 32px 0 32px;
    outline: none;
    max-width: 400px;
    min-width: 6ch;
    height: 1.4em;
    border-radius: 6px;
    background-color: #f1f1f1;
    color: #626c91;
    caret-color: #626c91;
    border: solid 2px #f1f1f1;
}

/* ------------ Find the word from the definition task ---------------- */
.find-the-word-bold {
    font-family: freight-sans-pro b, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
}

.gg-context-translation {
    text-decoration: underline;
    font-family: freight-sans-pro b, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
}

.translate-no-bullet-list {
    list-style-type: none;
    font-size: 16.5px;
    white-space: pre-wrap;
    line-height: 1.5em;
    color: #2C3143;
}

::-webkit-input-placeholder { /* Edge */
    color: #B2B8CB;
    padding-bottom: 0;
    position: relative;
    top: 2px;
}

::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B2B8CB;
    padding-bottom: 0;
    position: relative;
    top: 2px;
}

::placeholder {
    color: #B2B8CB;
    padding-bottom: 0;
    position: relative;
    top: 2px;
}

.translate-italic {
    font-style: italic;
}

.translate-info {
    font-size: 16px;
    height: 20px;
    padding: 0 0 5px 8px;
}

.translate-info:hover {
    cursor: pointer;
}

.popover{
    max-width: 400px;
}