.personalized_flex_item {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 170px;
  height: auto;
  margin: 0 20px 15px 20px;
  text-align: center;
  cursor: pointer;
  word-wrap: break-word;
}

@media (max-width: 600px) {
    .personalized_flex_item {
        margin: 10px 5px 10px 5px;
    }
}

.personalized_flex_item:hover {
  box-shadow: 0 2px 8px rgb(165, 213, 190);
  opacity: 0.9;
}

.personalized_image {
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  padding: 5px 15px 5px 15px;
}

.personalized_image img {
  /*width: 100%;*/
  object-fit: cover;
}

.personalized_title {
  text-align: center;
  color: #2c292b;
  line-height: 1.25rem;
  padding: 0.7rem 0 0 0;
  font-weight: bolder;
}

.personalized_author {
  text-align: center;
  color: #757175;
  line-height: 1.25rem;
  padding: 0.2rem 0 0.8rem 0;
  font-size: 0.8rem;
}