.cloze_parallel_text {
    padding: 5px 60px 40px 60px;
    color: #2C3143;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.cloze_parallel_sefr {
    background-color: #D9F6E6;
    padding: 4px 10px;
    border-radius: 4px;
    font-family: freight-sans-pro b, sans-serif;
    font-size: 15px;
    color: #2C3143;
}

.cloze_parallel_round {
    margin-left: 16px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_parallel_round:hover {
    opacity: 0.8;
}