.pe-progress-bar {
    height: 4px;
    background-color: #e1e1e1;
    margin: 0 20px 20px 20px;
}

.pe-flex-end {
    display: flex;
    justify-content: flex-end;
}

.pe-flex-space-between {
    display: flex;
    justify-content: space-between;
}

.pe-flex-float {
    display: flex;
    float: left;
}

.pe-flex-center {
    display: flex;
    justify-content: center;
}

.pe-img {
    height: 15px;
    padding-right: 10px;
}

.pe-text {
    white-space: pre-wrap;
}

.pe-text-gradient {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), color-stop(-10%, rgb(0, 0, 0)), to(rgba(255, 255, 255, 0)));
}

.pe-answer-input {
    width: 300px;
    height: 30px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding-left: 10px;
    background: rgba(239, 239, 239, 0.53);
}

.pe-answer-input-correct {
    width: 300px;
    height: 30px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding-left: 10px;
    background: #fcfcfc;
    color: #43D281;
}

.pe-answer-input:focus {
    outline: none;
}

.pe-answer-input-correct:focus {
    outline: none;
}

.pe-column-header {
    font-family: freight-sans-pro b, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2C3143;
    padding-right: 10px;
}

.pe-completed-exercise-name {
    font-family: freight-sans-pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #B2B8CB;
}

.pe-current-exercise-name {
    font-family: freight-sans-pro b, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2C3143;
}

.pe-start-button {
    width: 145px;
}

.pe-answer-input-correct::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #43D281;
}

.pe-answer-input-correct:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #43D281;
    opacity: 1;
}

.pe-answer-input-correct::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #43D281;
    opacity: 1;
}

.pe-answer-input-correct:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #43D281;
}

.pe-answer-input-correct::-ms-input-placeholder { /* Microsoft Edge */
    color: #43D281;
}

.pe-answer-input-correct::placeholder { /* Most modern browsers support this now. */
    color: #43D281;
}

.pe-answer-input-incorrect {
    width: 300px;
    height: 30px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding-left: 10px;
    background: #fcfcfc;
    color: #FE5C55;
}

.pe-answer-input-incorrect:focus {
    outline: none;
}

.pe-answer-input-incorrect::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #FE5C55;
}

.pe-answer-input-incorrect:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #FE5C55;
    opacity: 1;
}

.pe-answer-input-incorrect::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #FE5C55;
    opacity: 1;
}

.pe-answer-input-incorrect:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FE5C55;
}

.pe-answer-input-incorrect::-ms-input-placeholder { /* Microsoft Edge */
    color: #FE5C55;
}

.pe-answer-input-incorrect::placeholder { /* Most modern browsers support this now. */
    color: #FE5C55;
}

.pe-table-bottom-border {
    border-top: 1px solid #e1e1e1;
    padding-top: 8px;
}

.pe-hidden {
    display: none;
}

.pe-word-length {
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}

.pe-correct-word-length {
    display: inline-block;
    visibility: hidden;
}