.cloze_ex_header {
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cloze_ex_justify {
    text-align: justify;
    margin: 0 60px 60px 60px;
    padding-bottom: 60px;
    padding-top: 0;
}

.cloze_select_book_title {
    margin-left: 40px;
}

@media (max-width: 1300px) {
    .cloze_ex_justify {
        margin: 0 30px 30px 30px;
        padding-bottom: 20px;
    }

    .cloze_select_book_title {
        margin-left: 10px;
    }
}

@media (max-width: 1100px) {
    .cloze_ex_justify {
        margin: 0 14px 10px 14px;
        padding-bottom: 20px;
    }

    .cloze_select_book_title {
        margin-left: 0;
    }
}

.cloze_ex_target {
    font-family: freight-sans-pro, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
    text-justify: auto;
    display: inline;
    /*Consider the indent and tab*/
    white-space: pre-wrap;
}

.cloze_ex_footer {
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #D5DBE5;
    height: 104px;
}

.cloze_ex_footer_completed {
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #D9F6E6;
    height: 104px;
}

.cloze_ex_easy {
    background-color: #D9F6E6;
    border-radius: 6px;
    padding: 5px;
}

.cloze_ex_intermediate {
    background-color: #FEEEDE;
    border-radius: 6px;
    padding: 5px 8px 5px 8px;
}

.cloze_ex_hard {
    background-color: #CDE4E7;
    border-radius: 6px;
    padding: 5px;
}

.cloze_ex_hide {
    display: none;
}

.cloze_ex_cap_highlight {
    background-color: #ffe1aa;
}

.cloze_ex_correct_answer {
    color: #43D281;
    margin-left: -5px;
    margin-right: -5px;
}

.cloze_ex_replaced_answer {
    color: #6CD5E5;
    margin-left: -5px;
    margin-right: -5px;
}

.cloze_ex_wrong_answer_input {
    color: #FE5C55;
    caret-color: transparent;
    pointer-events: none;
    background-color: white;
    height: 0.8em;
}

.cloze_ex_answer_input {
    box-sizing: content-box;
    padding: 0px 8px 0px 8px;
    outline: none;
    max-width: 400px;
    min-width: 5ch;
    height: 1.4em;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #037889;
    caret-color: #037889;
    border: solid 1px #c5d5d9;
}

.cloze_ex_cap {
    color: #cbcbd3;
}

.tooltip-inner {
    background-color: #037889;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #037889;
}

.tooltip.show {
    opacity: 1;
}

.cloze_ex_hint {
    background-color: #68AEB8;
    border-radius: 20px;
    padding: 5px 7px 5px 7px;
    margin: 5px;
    cursor: help;
    font-size: 0.8em;
    color: white;
    border: 0;
}

.cloze_ex_hint:hover {
    transition: all 200ms ease-in;
    opacity: 0.8;
}

.cloze_ex_flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    color: #77002e;
    margin-right: 8px;
    height: 0.9em;
    padding-bottom: 3px;
}

.cloze_ex_link {
    color: #037889;
    font-size: 1.1em;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_ex_link:hover {
    color: #2192a1;
    font-size: 1.1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cloze_br {
    display: block;
    margin: 0.9rem 0;
}

.cloze_ex_noflip {
    margin-right: 8px;
    height: 0.9em;
    padding-bottom: 3px;
    padding-left: 6px;
}

.cloze_ex_flip_up {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    height: 0.9em;
}

.cloze_ex_dark {
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
    margin: 16px 0 20px 0;
    background-color: #2C3143;
}

.cloze_ex_dark:hover {
    background-color: #40465b;
}

.cloze_ex_outline_dark {
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
    margin: 0;
}

.cloze_ex_outline_dark:hover {
    background-color: #40465b;
}

.cloze_ex_side_hint_normal {
    text-align: right;
    display: inline-block;
    border: 2px solid #b2b8cb;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px 4px 16px 4px;
    padding: 4px 16px;
    color: #2C3143;
}

.cloze_ex_side_hint_solved {
    text-align: right;
    display: inline-block;
    border: 2px solid #43D281;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 4px 4px 16px 4px;
    padding: 4px 16px;
    color: #2C3143;
}

.cloze_ex_check {
    margin-right: 8px;
    margin-left: 0;
    pointer-events: none;
}

.cloze_ex_blur {
    color: transparent;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 6px;
}

.cloze_ex_translation_tooltip {
    color: #2C3143;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #b4adad;
    border-radius: 4px;
    background-color: white;
    padding: 10px;
    position: absolute;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22);

    animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.3s; /* don't forget to set a duration! */
}

.fa {
    margin-left: -8px;
    margin-right: 8px;
}

.cloze_ex_shuffle {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
}

.cloze_ex_label {
    color: #2C3143;
}

.cloze_ex_toggle_row {
    padding: 0 16px 0 16px;
    color: #2C3143;
}

.cloze_ex_slider_row {
    padding: 16px 16px 16px 16px;
    color: #2C3143;
}

.cloze_ex_down_arrow {
    display: flex;
    justify-content: flex-end;
    padding: 6px 24px 0 0;
    cursor: pointer;
}

.cloze_ex_slider_hidden {
    opacity: 0;
    padding: 0 16px 0 16px;
    height: 0;
    transition-duration: 0.3s;
}

.cloze_ex_slider_out {
    opacity: 1;
    height: 64px;
    margin: 16px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.cloze_ex_rotate {
    transform: rotateX(180deg);
}

.cloze_ex_right_div {
    height: auto;
}
