.main {
    padding-left: 0;

    -webkit-transition: padding-left 0.2s;
    -moz-transition: padding-left 0.2s;
    -o-transition: padding-left 0.2s;
    -ms-transition: padding-left 0.2s;
    transition: padding-left 0.2s;
}

.main-narrow {
    padding-left: 176px;

    -webkit-transition: padding-left 0.2s;
    -moz-transition: padding-left 0.2s;
    -o-transition: padding-left 0.2s;
    -ms-transition: padding-left 0.2s;
    transition: padding-left 0.2s;
}

@media (min-width: 200px) {
    .main {
        width: 100%;
    }
}

@media screen and (min-width: 1400px) {
    .main-container {
        width: 1400px;
        max-width: 1400px;
        display: inline-block;
    }
}