.AuthPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    padding-top: 40px;
}

/* Hooks button */
.login-button {
    cursor: pointer;
    display: block;
    font-size: 1em;
    margin: 20px auto 0px;
    width: 300px;
    height: 40px;
    border-radius: 10px;
    border-color: transparent;
    background-color: #eff0f4;
    box-shadow: 2px 2px 2px rgba(245, 246, 248, 0.5);
    position: relative;
}

/* Hooks button */
.logout-button {
    cursor: pointer;
    display: block;
    font-size: 1em;
    margin: 10px 0;
    width: 80px;
    height: 30px;
    border-radius: 6px;
    border-color: transparent;
    background-color: #f8f8f8;
    /*box-shadow: 2px 2px 2px rgba(245, 246, 248, 0.5);*/
    /*position: relative;*/
}

.login-button:hover,
.login-button:active {
  background-color: rgba(239, 240, 244, 0.84);
}


.buttonText {
    color: #4d4d4d;
}

.icon {
    height: 25px;
    width: 25px;
    position: absolute;
    left: 10px;
    align-items: center;
}
