.home-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}

.home-image {
    border-radius: 6px;
}
