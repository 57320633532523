@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #626C91;
    font-family: freight-sans-pro, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.holodeck_h1 {
    font-family: freight-sans-pro, sans-serif;
    font-size: 2.5rem;
    color: #2C3143;
}

.holodeck_h2 {
    font-family: freight-sans-pro, sans-serif;
    font-size: 1.5rem;
    color: #2C3143;
}

.holodeck_h3 {
    font-family: freight-sans-pro b, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
    padding-left: 0;
}

.holodeck_h4 {
    font-family: freight-sans-pro b, sans-serif, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
    padding-left: 0;
}

.holodeck_h5 {
    font-family: freight-sans-pro b, sans-serif, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #2C3143;
    padding-left: 0;
}

.image {
    width: 100%;
    height: 20rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.image img {
    width: 100%;
    object-fit: cover;
}

.actions {
    padding: 1.5rem;
    text-align: center;
}

.actions button {
    font: inherit;
    cursor: pointer;
    color: #77002e;
    border: 1px solid #77002e;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
}

.actions button:hover,
.actions button:active {
    background-color: #ffe2ed;
}

.customRadio input[type="radio"] {
    position: absolute;
    left: -9999px
}

.customRadio input[type="radio"] + label {
    position: relative;
    padding: 0 0 0 40px;
    cursor: pointer
}

.customRadio input[type="radio"] + label:before {
    content: '';
    background: #fff;
    border: 2px solid #b2b8cb;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0
}

.customRadio input[type="radio"] + label:after {
    content: '';
    background: #2c3143;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0;
    transform: scale(2);
    transition: transform 0.3s linear, opacity 0.3s linear
}

.customRadio input[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

.holo_dark_button {
    background-color: #2C3143;
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
}

.holo_button_unfocus {
    background-color: #2C3143;
    height: 32px;
    padding: 0 16px;
    border-radius: 8px;
}

.holo_dark_huge_button {
    background-color: #2C3143;
    height: 40px;
    width: 354px;
    padding: 0 16px;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .holo_dark_huge_button {
        width: 300px;
    }
}

::selection {
    background: #BDDBDF; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: #BDDBDF; /* Gecko Browsers */
}

.holo-inactive-link {
    line-height: 1.5em;
    font-family: freight-sans-pro, sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #626C91;
}

.holo-inactive-link:hover {
    color: #68AEB8;
}
